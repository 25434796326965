import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
    Button,
    ColumnLayout,
    Container, FormField,
    Header, Select,
    SpaceBetween,
    StatusIndicator
} from "@amzn/awsui-components-react/polaris";
import DetailTable from "./DetailTable";
import ActionMenu from "./ActionMenu";
// import {JSON_HEADERS} from "../util/constants";
import {API} from "aws-amplify";
import {API_NAME, GET_FLYER_DETAILS} from "../util/constants";

const getRequiredFields = flyerType => {
    const REQUIRED_FIELDS = {
        "National": ["rank", "name", "promoTypeStr", "salePriceStr", "imageUrl", "promotionImageUrl", "disclaimer"],
        "Regional": ["rank", "name", "promoTypeStr", "salePriceStr", "imageUrl", "promotionImageUrl", "disclaimer"]
    };

    if (flyerType in REQUIRED_FIELDS) {
        return REQUIRED_FIELDS[flyerType];
    }
    return ["rank", "name", "promoTypeStr", "regPriceStr", "salePriceStr", "imageUrl", "promotionImageUrl", "disclaimer"];
};

export default class FlyerDetails extends Component {
    constructor(props) {
        super(props);
        // let allowed = (flyers => {
        //     let idSet = {};
        //     flyers.forEach(flyer => idSet[flyer.flyerId] = true);
        //     return idSet;
        // })(props.flyers);
        this.state = {
            // allowed: allowed[props.match.params.id] === true,
            userId: props.user,
            flyerId: props.match.params.id,
            allowed: true,
            flyerDetails: {},
            permission: props.permission,
            loading: false,
            editing: false,
            missingRequiredData: false
        };
        this.resetDetails = this.resetDetails.bind(this);
        this.getFlyerDetails = this.getFlyerDetails.bind(this);
        this.missingRequiredData = this.missingRequiredData.bind(this);
    }

    resetDetails() {
        this.setState({
            flyerDetails: {},
            flyerId: this.props.match.params.id
        });
    }

    getFlyerDetails() {
        this.setState({
            flyerDetails: {},
            flyerId: this.props.match.params.id,
            loading: true
        }, () => {
            let requestOptions = {
                body: {
                    flyerId: this.state.flyerId,
                    userId: this.state.userId
                }
            };
            API.post(API_NAME, GET_FLYER_DETAILS, requestOptions)
                .then(response => {
                    let formattedPromotionList = response.uiFlyerPromotionsList.map(flyerEntry => {
                        if (flyerEntry.hasOwnProperty("asins")) {
                            if (flyerEntry.asins !== "") {
                                flyerEntry.asins = flyerEntry.asins.join(", ");
                            }
                        }
                        if (flyerEntry.hasOwnProperty("upcs")) {
                            if (flyerEntry.upcs !== "") {
                                flyerEntry.upcs = flyerEntry.upcs.join(", ");
                            }
                        }
                        if (flyerEntry.hasOwnProperty("rank")) {
                            flyerEntry.rank = (flyerEntry.rank === -1 || !flyerEntry.rank) ?
                                "" : flyerEntry.rank.toString();
                        }
                        if (flyerEntry.hasOwnProperty("emailRank")) {
                            flyerEntry.emailRank = (flyerEntry.emailRank === -1 || !flyerEntry.emailRank) ?
                                "" : flyerEntry.emailRank.toString();
                        }
                        return flyerEntry;
                    });
                    response.uiFlyerPromotionsList = formattedPromotionList;
                    let flyerType = response.flyerType;
                    this.setState({flyerDetails: response, loading: false,
                        missingRequiredData: this.missingRequiredData(formattedPromotionList, flyerType)
                    });
                })
                .catch(e => console.log(e));
        });
    }

    missingRequiredData(flyerDetails, flyerType) {
        let requiredFields = getRequiredFields(flyerType);
        for (let flyerDetail of flyerDetails) {
            for (let requiredField of requiredFields) {
                if (!flyerDetail[`${requiredField}`]) {
                    return true;
                }
            }
        }
        return false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            if (this.state.allowed) {
                this.getFlyerDetails();
            }
        }
    }

    componentDidMount() {
        if (this.state.allowed) {
            this.getFlyerDetails();
        }
    }

    render() {
        let flyerDetails = this.state.flyerDetails;

        let showRegionalDropdown = flyerDetails.storeGroups !== undefined && flyerDetails.storeGroups !== null;
        let regionalOptions = [];
        let currOption = {};

        if (showRegionalDropdown) {
            Object.entries(flyerDetails.storeGroups).forEach(keyValuePair => {
                if (keyValuePair[1].flyerId !== this.state.flyerId) {
                    regionalOptions.push({
                        label: keyValuePair[0],
                        value: keyValuePair[1].flyerId
                    });
                } else {
                    currOption = {label: keyValuePair[0], value: keyValuePair[1].flyerId}
                }
            })
        }

        let regionalDropdown = (
            <FormField
                label="Go To Related Flyer:"
            >
                <SpaceBetween direction="horizontal" size="s">
                    <Select
                        selectedOption={currOption}
                        onChange={({detail}) => {
                            this.props.history.push(`/flyer/${detail.selectedOption.value}`);
                        }}
                        options={regionalOptions}
                        selectedAriaLabel="Selected"
                    />
                    {currOption.label !== 'Region' ?
                    <Button onClick={() => {
                        this.props.history.push(`/stores-hierarchy?pricezone=${currOption.label}&flyerId=${this.state.flyerId}`);
                    }}>
                        View Price Zone Hierarchy
                    </Button> : null}


                </SpaceBetween>



            </FormField>
        );

        // TODO: not sure what to do with flyerDetails.storeId
        let flyerInformation = this.state.loading ? (
            <StatusIndicator type="loading">
                Loading
            </StatusIndicator>
        ) : (
            <SpaceBetween direction="vertical" size="xs">
                <span>Name: {flyerDetails.flyerName}</span>
                <span>Description: {flyerDetails.flyerDescription}</span>
                <span>Period: {flyerDetails.flyerPeriod}</span>
                {flyerDetails.regionName ? <span>Region: {flyerDetails.regionName}</span> : null}
                {flyerDetails.storeGroupName ? <span>PriceZone: {flyerDetails.storeGroupName}</span> : null}
                {flyerDetails.storeName ? <span>Store: {flyerDetails.storeName}</span> : null}
                <span></span>
                {showRegionalDropdown ? regionalDropdown : null}
            </SpaceBetween>
        );

        let permittedContent = (
            <Container>
                <Header
                    actions={

                        <SpaceBetween direction="horizontal" size="m">

                            <Button
                                onClick={() => {
                                    this.props.history.push(`/sale-preview/${this.state.flyerId}`,
                                        {
                                            saleEntries: flyerDetails.uiFlyerPromotionsList,
                                            flyerPeriod: flyerDetails.flyerPeriod
                                        });
                                }}
                                disabled={this.state.loading || this.state.editing}
                            >
                                Sale Preview
                            </Button>


                            <Button onClick={() => {
                                this.props.history.push(`/flyer-history/${this.state.flyerId}`);
                            }}>
                                Flyer History
                            </Button>


                            <Button onClick={() => {
                                this.props.history.push("/");
                            }}>
                                Return to Home
                            </Button>
                        </SpaceBetween>


                    }
                >
                    Flyer Details
                </Header>
                <SpaceBetween direction="vertical" size="xs">
                    <ColumnLayout columns={2}>
                        <Container
                            header={
                                <Header>
                                    Flyer Information
                                </Header>
                            }
                        >
                            {flyerInformation}
                        </Container>
                        {flyerDetails.flyerType === "National" && !flyerDetails.isFlyerEditableByUser ?
                            null :
                            <ActionMenu
                                flyerId={this.props.match.params.id}
                                flyerReviewStatus={flyerDetails.reviewStatus}
                                flyerType={flyerDetails.flyerType}
                                userId={this.props.user}
                                history={this.props.history}
                                flyerLoading={this.state.loading}
                                editing={this.state.editing}
                                missingRequiredData={this.state.missingRequiredData}
                                isFlyerEditableByUser={flyerDetails.isFlyerEditableByUser}
                            />
                        }
                    </ColumnLayout>
                    <DetailTable
                        userId={this.state.userId}
                        flyerId={this.props.match.params.id}
                        history={this.props.history}
                        details={flyerDetails.uiFlyerPromotionsList}
                        storeId={flyerDetails.storeId}
                        editControlsEnabled={flyerDetails.isFlyerEditableByUser}
                        loading={this.state.loading}
                        getDetails={this.getFlyerDetails}
                        flyerPeriod={flyerDetails.flyerPeriod}
                        editWatcher={(editStatus) => {
                            this.setState({ editing: editStatus });
                        }}
                        flyerType={flyerDetails.flyerType}
                    />
                </SpaceBetween>
            </Container>
        )

        let unknownPermission = null;

        let redirect = (
            <Redirect to="/"/>
        )

        return this.state.allowed === null ? unknownPermission :
            (this.state.allowed ? permittedContent : redirect);
    }
}