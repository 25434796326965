import React from "react";
import {
    Popover,
    SpaceBetween,
    StatusIndicator,
    Textarea
} from "@amzn/awsui-components-react/polaris";

export const ensureEditableValue = (value) => {
    if (!value) {
        return "";
    }
    return value;
}

export const IMAGE_URL_SPLITTER = " ; ";
export const COMMA_SEPARATOR = ',';
export const INVALID_SPACE = ' ';
export const MAX_UPCS_PER_DELIMITER = 1;

export const CSV_REQUIRED_FIELDS = [
    "Name", "Brand", "StartDate", "EndDate", "PromoType", "PromoTypeValueX", "PromoTypeValueY", "PromoTypeValueZ"
];

export const PROMO_TYPE = [
    {value: "X_PERCENT_OFF", example: ["20% off"]},
    {value: "X_PERCENT_OFF_PER_Y", example: ["25% off per lb, 25% off ea"]},
    {value: "X_FOR_DOLLAR_Y", example: ["2 for $5"]},
    {value: "DOLLAR_X_PER_Y", example: ["$5/lb, $2 ea, $3 per 2"]},
    {value: "BUY_X_GET_Y_FREE", example:["Buy 1 Get 1 free"]},
    {value: "BUY_X_GET_Y_Z_PERCENT_OFF", example: ["Buy 1 Get 1 50% off"]},
    {value: "DOLLAR_X_OFF", example: ["$5 off"]}
];

export const getUserFriendlyPromoType = (promoType, X, Y, Z) => {
    let promoTypeMappings = {
        X_PERCENT_OFF: `${X}% off`,
        X_PERCENT_OFF_PER_Y: `${X}% off per ${Y}`,
        X_FOR_DOLLAR_Y: `${X} for $${Y}`,
        DOLLAR_X_PER_Y: Y === "lb" ? `$${X}/${Y}` : Y === "ea" ? `$${X} ${Y}` : `$${X} per ${Y}`,
        BUY_X_GET_Y_FREE: `Buy ${X} Get ${Y} free`,
        BUY_X_GET_Y_Z_PERCENT_OFF: `Buy ${X} Get ${Y} ${Z}% off`,
        DOLLAR_X_OFF: `$${X} off`
    }
    return promoTypeMappings[promoType];
};

export const csvLabelToInternalLabelMapping = {
    Name: "name",
    Brand: "brand",
    PromoType: "promoType",
    PromoTypeValueX: "x",
    PromoTypeValueY: "y",
    PromoTypeValueZ: "z",
    StartDate: "startDate",
    EndDate: "endDate"
};

export const CSV_VIEW_COLUMN_DEFINITIONS = [
    {
        id: "brand",
        header: "Brand",
        cell: e => e.brand,
        sortingField: "brand"
    },
    {
        id: "name",
        header: "Name",
        cell: e => e.name,
        sortingField: "name"
    },
    {
        id: "startDate",
        header: "Start Date (YYYY-MM-DD)",
        cell: e => e.startDate,
        sortingField: "startDate"
    },
    {
        id: "endDate",
        header: "End Date (YYYY-MM-DD)",
        cell: e => e.endDate,
        sortingField: "endDate"
    },
    {
        id: "promoTypeStr",
        header: "Master Promotion Price",
        cell: e => e.promoTypeStr,
        sortingField: "promoTypeStr"
    }
];

export const CSV_EDIT_COLUMN_DEFINITIONS = [
    {
        id: "brand",
        header: "Brand",
        cell: e => ensureEditableValue(e.brand),
        sortingField: "brand"
    },
    {
        id: "name",
        header: "Name",
        cell: e => ensureEditableValue(e.name),
        sortingField: "name"
    },
    {
        id: "startDate",
        header: "Start Date (YYYY-MM-DD)",
        cell: e => ensureEditableValue(e.startDate),
        sortingField: "startDate"
    },
    {
        id: "endDate",
        header: "End Date (YYYY-MM-DD)",
        cell: e => ensureEditableValue(e.endDate),
        sortingField: "endDate"
    },
    {
        id: "promoTypeStr",
        header: "Master Promotion Price",
        cell: e => e.promoTypeStr,
        sortingField: "promoTypeStr"
    },
    {
        id: "promoTypeEdit",
        header: "Edit Master Promotion Price",
        cell: e => {}
    }
];

export const DETAILS_VIEW_COLUMN_DEFINITIONS = [
    {
        id: "tier",
        header: "Tier",
        cell: e => ensureEditableValue(getTierDisplayValue(e.tier)),
        sortingField: "tier"
    },
    {
        id: "type",
        header: "Promotion Type",
        cell: e => ensureEditableValue(getPromoTypeDisplayValue(e.type)),
        sortingField: "type"
    },
    {
        id: "promotionGrouping",
        header: "Promotion Grouping",
        cell: e => ensureEditableValue(getPromotionGroupingDisplayValue(e.promotionGrouping)),
        sortingField: "promotionGrouping"
    },
    {
        id: "rank",
        header: "Rank",
        cell: e => ensureEditableValue(e.rank),
        sortingComparator: (e1, e2) => {
            let rank1 = parseInt(e1.rank) ? e1.rank : 0;
            let rank2 = parseInt(e2.rank) ? e2.rank : 0;
            return rank1 - rank2;
        }
    },
    {
        id: "emailRank",
        header: "Email Rank",
        cell: e => ensureEditableValue(e.emailRank),
        sortingComparator: (e1, e2) => {
            let rank1 = parseInt(e1.emailRank) ? e1.emailRank : 0;
            let rank2 = parseInt(e2.emailRank) ? e2.emailRank : 0;
            return rank1 - rank2;
        }
    },
    {
        id: "brand",
        header: "Brand",
        cell: e => ensureEditableValue(e.brand),
        sortingField: "brand"
    },
    {
        id: "name",
        header: "Name",
        cell: e => ensureEditableValue(e.name),
        sortingField: "name"
    },
    {
        id: "promotionImageUrl",
        header: "Flyer 2.0 Promo Image",
        cell: e => !e.promotionImageUrl || e.promotionImageUrl === " " ? ""
            : <img src={e.promotionImageUrl} alt=" " width="" height="180" crossOrigin={"anonymous"}/>,
        sortingField: "promotionImageUrl"
    },
    {
        id: "imageUrl",
        header: "Promotion Image",
        cell: e => !e.imageUrl || e.imageUrl === " " ? ""
            : <img src={e.imageUrl} alt=" " width="" height="180" crossOrigin={"anonymous"}/>,
        sortingField: "imageUrl"
    },
    {
        id: "promoTypeStr",
        header: "Master Promotion Price",
        cell: e => e.promoTypeStr,
        sortingField: "promoTypeStr"
    },
    {
        id: "regPriceStr",
        header: "Regular Price",
        cell: e => ensureEditableValue(e.regPriceStr),
        sortingField: "regPriceStr"
    },
    {
        id: "salePriceStr",
        header: "Sale Price",
        cell: e => ensureEditableValue(e.salePriceStr),
        sortingField: "salePriceStr"
    },
    {
        id: "primePriceString",
        header: "Prime Price",
        cell: e => ensureEditableValue(e.primePriceString),
        sortingField: "primePriceString"
    },
    {
        id: "headlineString",
        header: "Headline",
        cell: e => <Textarea
            value={e.headlineString}
            autoComplete={false}
            readOnly
        />,
        width: 300,
        minWidth: 300,
        sortingField: "headlineString"
    },
    {
        id: "romanceCopyString",
        header: "Romance Copy",
        cell: e => <Textarea
            value={e.romanceCopyString}
            autoComplete={false}
            readOnly
        />,
        width: 300,
        minWidth: 300,
        sortingField: "romanceCopyString"
    },
    {
        id: "packageSize",
        header: "Package Size",
        cell: e => ensureEditableValue(e.packageSize),
        sortingField: "packageSize"
    },
    {
        id: "startDate",
        header: "Start Date (YYYY-MM-DD)",
        cell: e => ensureEditableValue(e.startDate),
        sortingField: "startDate"
    },
    {
        id: "endDate",
        header: "End Date (YYYY-MM-DD)",
        cell: e => ensureEditableValue(e.endDate),
        sortingField: "endDate"
    },
    {
        id: "disclaimer",
        header: "Disclaimer",
        cell: e => <Textarea
            value={e.disclaimer}
            autoComplete={false}
            readOnly
        />,
        width: 300,
        minWidth: 300,
        sortingField: "disclaimer"
    },
    {
        id: "inStoreFlg",
        header: "In-Store Flag",
        cell: e => ensureEditableValue(getInStoreFlagDisplayValue(e.inStoreFlg)),
        sortingField: "inStoreFlg"
    },
    {
        id: "primeEligibility",
        header: "Prime Eligibility",
        cell: e => ensureEditableValue(getPrimeEligibilityDisplayValue(e.primeEligibility)),
        sortingField: "primeEligibility"
    },
    {
        id: "automationFlags",
        header: "Automation Flags",
        cell: e => ensureEditableValue(setAutomationFlagsDisplayValue(e.automationFlags)),
        sortingField: "automationFlags"
    },
    {
        id: "automatedRegPriceFlg",
        header: "Regular Price Flag",
        cell: e => ensureEditableValue(getAutomatedRegPriceFlgDisplayValue(e.automatedRegPriceFlg)),
        sortingField: "automatedRegPriceFlg"
    },
    {
        id: "upcs",
        header: "Scancode(s)",
        cell: e => <Textarea
            value={e.upcs}
            autoComplete={false}
            readOnly
        />,
        width: 300,
        minWidth: 300,
        sortingField: "upcs"
    },
    {
        id: "asins",
        header: "ASIN(s)",
        cell: e => <Textarea
            value={e.asins}
            autoComplete={false}
            readOnly
        />,
        width: 300,
        minWidth: 300,
        sortingField: "asins"
    }
];

export const DETAILS_EDIT_COLUMN_DEFINITIONS = [
    {
        id: "tier",
        header: "Tier",
        cell: e => ensureEditableValue(getTierDisplayValue(e.tier))
    },
    {
        id: "type",
        header: "Promotion Type",
        cell: e => ensureEditableValue(getPromoTypeDisplayValue(e.type))
    },
    {
        id: "promotionGrouping",
        header: "Promotion Grouping",
        cell: e => ensureEditableValue(getPromotionGroupingDisplayValue(e.promotionGrouping))
    },
    {
        id: "rank",
        header: "Rank",
        cell: e => ensureEditableValue(e.rank)
    },
    {
        id: "emailRank",
        header: "Email Rank",
        cell: e => ensureEditableValue(e.emailRank)
    },
    {
        id: "brand",
        header: "Brand",
        cell: e => ensureEditableValue(e.brand)
    },
    {
        id: "name",
        header: "Name",
        cell: e => ensureEditableValue(e.name)
    },
    {
        id: "promotionImageUrl",
        header: "Flyer 2.0 Promo Image",
        cell: e => !e.promotionImageUrl || e.promotionImageUrl === " " ? ""
            : (e.promotionImageUrl.includes(IMAGE_URL_SPLITTER) ?
                <img
                    src={e.promotionImageUrl.split(IMAGE_URL_SPLITTER)[0]}
                    alt=" " width="" height="180" crossOrigin={"anonymous"}
                />
                : <img src={e.promotionImageUrl} alt=" " width="" height="180" crossOrigin={"anonymous"}/>),
    },
    {
        id: "promotionImageUpload",
        header:(<SpaceBetween direction="horizontal" size="xs">
            Upload Flyer 2.0 Promo Image
            <Popover
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <div>
                        Image that displays in the new flyer experience. Super Hero promos - can be a lifestyle or normal product shot and In-Store Only promos - images used should NOT have in-store only bugs. All other images can use the same file in both image fields.
                    </div>
                }
            >
                <div style={{cursor: "pointer"}}>
                    <StatusIndicator type="info">
                        Info
                    </StatusIndicator>
                </div>
            </Popover>
        </SpaceBetween>),
        minWidth: 300,
        cell: e => {}
    },
    {
        id: "imageUrl",
        header: "Promotion Image",
        cell: e => !e.imageUrl || e.imageUrl === " " ? ""
            : (e.imageUrl.includes(IMAGE_URL_SPLITTER) ?
                <img
                    src={e.imageUrl.split(IMAGE_URL_SPLITTER)[0]}
                    alt=" " width="" height="180" crossOrigin={"anonymous"}
                />
                : <img src={e.imageUrl} alt=" " width="" height="180" crossOrigin={"anonymous"}/>),
    },
    {
        id: "imageUpload",
        header: "Upload Promotion Image",
        minWidth: 300,
        cell: e => {}
    },
    {
        id: "promoTypeStr",
        header: "Master Promotion Price",
        cell: e => e.promoTypeStr
    },
    {
        id: "promoTypeEdit",
        header: "Edit Master Promotion Price"
    },
    {
        id: "regPriceStr",
        header: (<SpaceBetween direction="horizontal" size="xs">
            Regular Price
            <Popover
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <div>
                        To update the regular price manually, the "Regular Price Flag" checkbox should be unchecked for the promotion.
                    </div>
                }
            >
                <div style={{cursor: "pointer"}}>
                    <StatusIndicator type="info">
                        Info
                    </StatusIndicator>
                </div>
            </Popover>
        </SpaceBetween>),
        minWidth: 190,
        cell: e => ensureEditableValue(e.regPriceStr)
    },
    {
        id: "salePriceStr",
        header: "Sale Price",
        cell: e => ensureEditableValue(e.salePriceStr)
    },
    {
        id: "primePriceString",
        header: (<SpaceBetween direction="horizontal" size="xs">
            Prime Price
            <Popover
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <div>
                        If a promotion is National Sale and Prime Eligible, the promotion should have Prime Price
                    </div>
                }
            >
                <div style={{cursor: "pointer"}}>
                    <StatusIndicator type="info">
                        Info
                    </StatusIndicator>
                </div>
            </Popover>
        </SpaceBetween>),
        minWidth: 170,
        cell: e => ensureEditableValue(e.primePriceString)
    },
    {
        id: "headlineString",
        header: "Headline",
        cell: e => ensureEditableValue(e.headlineString),
        width: 300,
        minWidth: 300
    },
    {
        id: "romanceCopyString",
        header: "Romance Copy",
        cell: e => ensureEditableValue(e.romanceCopyString),
        width: 300,
        minWidth: 300
    },
    // {
    //     id: "itemSelect",
    //     header: "Select Items",
    //     cell: e => {}
    // },
    {
        id: "packageSize",
        header: "Package Size",
        cell: e => ensureEditableValue(e.packageSize)
    },
    {
        id: "startDate",
        header: "Start Date (YYYY-MM-DD)",
        cell: e => ensureEditableValue(e.startDate)
    },
    {
        id: "endDate",
        header: "End Date (YYYY-MM-DD)",
        cell: e => ensureEditableValue(e.endDate)
    },
    {
        id: "disclaimer",
        header: (<SpaceBetween direction="horizontal" size="xs">
            Disclaimer
            <Popover
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <div>
                        If Package Size is included in the promotion, the Package Size must be placed within
                        parentheses and added to the beginning of the disclaimer.
                        <br/>
                        Ex: (6 oz) While supplies last.
                    </div>
                }
            >
                <div style={{cursor: "pointer"}}>
                    <StatusIndicator type="info">
                        Info
                    </StatusIndicator>
                </div>
            </Popover>
        </SpaceBetween>),
        cell: e => ensureEditableValue(e.disclaimer),
        width: 300,
        minWidth: 300
    },
    {
        id: "inStoreFlg",
        header: "In-Store Flag",
        cell: e => ensureEditableValue(getInStoreFlagDisplayValue(e.inStoreFlg))
    },
    {
        id: "primeEligibility",
        header: "Prime Eligibility",
        cell: e => ensureEditableValue(getPrimeEligibilityDisplayValue(e.primeEligibility))
    },

    {
        id: "automationFlags",
        header: (<SpaceBetween direction="horizontal" size="xs">
            Automation Flags
            <Popover
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <div>
                        Wine_Automation flag cannot be updated after national flyer is approved. Only way would be to delete the promotion entry and add a new promotion entry with correct value.
                    </div>
                }
            >
                <div style={{cursor: "pointer"}}>
                    <StatusIndicator type="info">
                        Info
                    </StatusIndicator>
                </div>
            </Popover>
        </SpaceBetween>),
        minWidth: 215,
        cell: e => ensureEditableValue(setAutomationFlagsDisplayValue(e.automationFlags))
    },
    {
        id: "automatedRegPriceFlg",
        header: "Regular Price Flag",
        cell: e => ensureEditableValue(getAutomatedRegPriceFlgDisplayValue(e.automatedRegPriceFlg))
    },
    {
        id: "upcs",
        header: "Scancode(s)",
        cell: e => ensureEditableValue(e.upcs),
        width: 300,
        minWidth: 300
    },
    {
        id: "asins",
        header: "ASIN(s)",
        cell: e => ensureEditableValue(e.asins),
        width: 300,
        minWidth: 300
    }
];

export const TRIMMABLE_FIELDS = new Set(
    ["rank", "emailRank", "brand", "name", "regPriceStr", "salePriceStr", "primePriceString", "headlineString", "romanceCopyString",
        "packageSize", "disclaimer", "upcs", "asins"]
);

export function getEditConstraint(attribute, state={}) {
    let constraints = {
        rank: val => {
            if (val === "") {
                return true;
            }
            let parsedVal = parseInt(val);
            return parsedVal >= 1 && parsedVal.toString() === val;
        },
        emailRank: val => {
            if (val === "") {
                return true;
            }
            let parsedVal = parseInt(val);
            return parsedVal >= 1 && parsedVal.toString() === val;
        },
        upcs: val => {
            if(val === "") {
                return true;
            }
            if(val.trim().slice(-1) === COMMA_SEPARATOR) {
                return false;
            }
            const upcs = val.split(COMMA_SEPARATOR);
            for (let i = 0; i < upcs.length; i++) {
                if (upcs[i].trim().split(INVALID_SPACE).length > MAX_UPCS_PER_DELIMITER) {
                    return false;
                }
            }
            return true;
        },
        asins: val => {
            return true;
        },
        regPriceStr: val => {
            return true;
        },
        salePriceStr: val => {
            return true;
        },
        primePriceString: val => {
            return true;
        },
        headlineString: val => {
            return true;
        },
        romanceCopyString: val => {
            return true;
        },
        startDate: (val, state) => {
            if (!val) return false;
            return true;
        },
        endDate: (val, state) => {
            if (!val) return false;
            return true;
        },
        disclaimer: (val) => {
            if(val.length > 350) return false;
            return true;
        }
    }
    if (!(attribute in constraints)) {
        return val => true;
    }
    return constraints[attribute];
};

export const SALE_TYPE_CONSTANTS = {
    DEFAULT_SELECTION: {label: "NS", value: "NATIONAL_SALE"},
    OPTIONS: [
        { label: "NS", value: "NATIONAL_SALE" },
        { label: "PMD", value: "PRIME_MEMBER_DEAL" }
    ]
};

export const AUTOMATION_FLAGS_CONSTANTS = {
    DEFAULT_SELECTION: {is_wine: 0},
    IS_WINE: "is_wine"
};

export const TIER_CONSTANTS = {
    DEFAULT_SELECTION: {label: "STANDARD TILE", value: "STANDARD_TILE"},
    OPTIONS: [
        { label: "SUPER HERO", value: "SUPER_HERO" },
        { label: "HERO", value: "HERO" },
        { label: "SUB HERO", value: "SUB_HERO" },
        { label: "STANDARD TILE", value: "STANDARD_TILE" }
    ]
};

export function getTierDisplayValue(type) {
    switch (type) {
        case 'SUPER_HERO':
            return 'SUPER HERO';
        case 'HERO':
            return 'HERO';
        case 'SUB_HERO':
            return 'SUB HERO';
        case 'STANDARD_TILE':
            return 'STANDARD TILE';
        case 'T1':
            return 'T1';
        case 'T2':
            return 'T2';
        case 'T3':
            return 'T3';
        default:
            return '';
    }
}

export function getPromoTypeDisplayValue(type) {
    switch (type) {
        case 'NATIONAL_SALE':
            return 'NS';
        case 'PRIME_MEMBER_DEAL':
            return 'PMD';
        default:
            return '';
    }
}

export function getInStoreFlagDisplayValue(type) {
    switch (type) {
        case 1:
            return '✓';
        case 0:
            return 'x';
        default:
            return 'x';
    }
}
export function getPrimeEligibilityDisplayValue(type) {
    switch (type) {
        case 1:
            return '✓';
        case 0:
            return 'x';
        default:
            return '';
    }
}
export function getAutomatedRegPriceFlgDisplayValue(type) {
    switch (type) {
        case 1:
            return '✓';
        case 0:
            return 'x';
        default:
            return '✓';
    }
}

export function setAutomationFlagsDisplayValue(type){
    let automationFlagMap = new Map();
    if (type !== undefined && type !== null && type !== '' && Object.keys(type).length !== 0) {
        Object.entries(type).forEach(keyValuePair => {
            if (AUTOMATION_FLAGS_CONSTANTS.DEFAULT_SELECTION.hasOwnProperty(keyValuePair[0])){
                automationFlagMap.set(keyValuePair[0], keyValuePair[1]);
            }
        })
    }
    else {
        Object.entries(AUTOMATION_FLAGS_CONSTANTS.DEFAULT_SELECTION).forEach(keyValuePair => {
            automationFlagMap.set(keyValuePair[0], keyValuePair[1])
        })
    }
    return getAutomationFlagsDisplayValue(automationFlagMap);
}

export function getAutomationFlagsDisplayValue(result){
    let output = [];
    result.forEach((value, key) =>
        output.push(
            <div>
                {key} : {value ? '✓' : 'x'}
            </div>
        )
    );
    return output;
}

export function getHighlightFields(flyerType) {
    const HIGHLIGHT_FIELDS = {
        "National": {
            "rank": "red",
            "name": "red",
            "brand": "gold",
            "promoTypeStr": "red",
            "salePriceStr": "red",
            "promotionImageUrl": "red",
            "imageUrl": "red",
            "disclaimer": "red"
        },
        "Regional": {
            "rank": "red",
            "name": "red",
            "brand": "gold",
            "promoTypeStr": "red",
            "salePriceStr": "red",
            "promotionImageUrl": "red",
            "imageUrl": "red",
            "disclaimer": "red"
        }
    };

    if (flyerType in HIGHLIGHT_FIELDS) {
        return HIGHLIGHT_FIELDS[flyerType];
    }
    return {
        "rank": "red",
        "name": "red",
        "brand": "gold",
        "promoTypeStr": "red",
        "regPriceStr": "red",
        "salePriceStr": "red",
        "promotionImageUrl": "red",
        "imageUrl": "red",
        "disclaimer": "red"
    }
}

export const PROMOTION_GROUPING_CONSTANTS = {
    DEFAULT_SELECTION: {label: "Single", value: "SINGLE"},
    OPTIONS: [
        { label: "Single", value: "SINGLE" },
        { label: "Grouped", value: "GROUPED" }
    ]
};

export function getPromotionGroupingDisplayValue(type) {
    switch (type) {
        case 'SINGLE':
            return 'Single';
        case 'GROUPED':
            return 'Grouped';
        default:
            return '';
    }
}
